import { Email, Language, LinkedIn, MoreVert, Place } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, IconButton, Link, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, Stack, Tooltip, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { CalendarIcon } from "@mui/x-date-pickers";
import ServerProxy from "./serverProxy";


export default function ListEventItem(props) {

    const theme = useTheme();
    const navigate = useNavigate();

    const { item, flyTo } = props;

    const [bestMatch, setBestMatch] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);


    const { firstname, lastname, avatarUrl, email, linkedInUrl } = item.user;
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }


    const onClickActionEvent = async (item) => {

        const profile = await ServerProxy.getDefaultProfile(item.user.id);

        if (profile) {
            return navigate('/user/' + item.user.id + '/profile/' + profile.id)
        }
    }


    useEffect(() => {
        setBestMatch(parseFloat(item.match) >= config.bestMatchThreshold);
    }, [item.match]);


    const ListItemStyle = {
        p: 0,
        m: 0,
        mb: 1,
        width: '100%',

        borderRadius: '5px',
        backgroundColor: bestMatch ? theme.palette.primary.dark : 'background.paper',
        boxShadow: 2
    }


    const borderStyle = {
        border: 2,
        borderColor: bestMatch ? theme.palette.primary.main : 'transparent',

        borderImage: bestMatch ? 'inherit' : 'linear-gradient(to right, rgba(192, 8, 127, 0.7) 0%, rgba(192, 8, 127, 0.7) 22%,rgba(236, 227, 20, 0.7) 100%)',
        borderImageSlice: 1,

        borderRadius: '5px',
    }


    return (
        <ListItem sx={{ ...ListItemStyle }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', p: 1, ...borderStyle }}>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    <ListItemAvatar>
                        <Avatar
                            sx={{ cursor: 'pointer', mr: .5, border: 2, borderColor: bestMatch ? grey[100] : 'primary.main' }}
                            alt={firstname + ' ' + lastname}
                            onClick={() => onClickActionEvent(item)} aria-label={firstname + ' ' + lastname} title={firstname + ' ' + lastname}
                            src={avatarUrl ? config.server.backetUrl + avatarUrl : null}>{firstname.charAt(0)}</Avatar>
                    </ListItemAvatar>

                    <ListItemText
                        sx={{ flexGrow: 1, m: 0, p: 0 }}

                        primary={<Typography
                            onClick={() => onClickActionEvent(item)} title={firstname + ' ' + lastname}
                            sx={{ cursor: 'pointer', fontSize: '.8rem', fontWeight: 'bold', color: bestMatch ? grey[100] : 'inherit' }}
                            variant="h6">{firstname + ' ' + lastname}</Typography>}

                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <Tooltip title="Contacts">
                            <IconButton onClick={handleClick}>
                                <Email sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}

                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {email &&

                                <ListItemButton component={Link} target="_blank" href={`mailto:${email}`} >
                                    <Email color="primary" sx={{ mr: 1, fontSize: 20 }} />
                                    <ListItemText ><Typography variant="body2">{email}</Typography></ListItemText>
                                </ListItemButton>
                            }

                            {linkedInUrl &&
                                <ListItemButton component={Link} target="_blank" href={linkedInUrl} >
                                    <LinkedIn color="primary" sx={{ mr: 1, fontSize: 20 }} />
                                    <ListItemText ><Typography variant="body2">LinkedIn</Typography></ListItemText>
                                </ListItemButton>
                            }

                        </Menu>
                    </Box>
                </Box>

                <Divider sx={{ width: '100%', my: 1, backgroundColor: bestMatch ? grey[100] : 'inherit' }} />


                <Box sx={{ width: '100%', mb: 2 }}>
                    <Typography sx={{ color: bestMatch ? grey[100] : 'inherit', fontSize: '.9rem' }} variant="body1">{item.name}</Typography>
                </Box>


                <Box sx={{ width: '100%' }}>
                    <Stack spacing={0}>

                        {item.date_at && (
                            <Box sx={{ width: '100%' }}>
                                <Button size="small" sx={{ textTransform: 'none' }} startIcon={<CalendarIcon sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />} >
                                    <Typography sx={{ fontSize: '.8rem', color: bestMatch ? grey[100] : grey[900] }} variant="body2">{new Date(item.date_at).toLocaleDateString()} {new Date(item.date_at).toLocaleTimeString().slice(0, -3)}</Typography>
                                </Button>
                            </Box>
                        )}

                        {item.place && (
                            <Box sx={{ width: '100%' }}>
                                <Button size="small" sx={{ textTransform: 'none' }} onClick={() => { flyTo({ longitude: item.position?.longitude ?? 0, latitude: item.position?.latitude ?? 0 }) }} startIcon={<Place sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />} >
                                    <Typography sx={{ fontSize: '.8rem', color: bestMatch ? grey[100] : grey[900] }} variant="body2">{item.place}</Typography>
                                </Button>
                            </Box>
                        )}

                        {item.url && (
                            <Box sx={{ width: '100%' }}>
                                <Button size="small" sx={{ textTransform: 'none' }} component={Link} href={item.url} startIcon={<Language sx={{ color: bestMatch ? grey[100] : theme.palette.primary.main }} />} >
                                    <Typography sx={{ fontSize: '.8rem', color: bestMatch ? grey[100] : grey[900] }} variant="body2">{'Go to web page'}</Typography>
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Box>


            </Box>
        </ListItem>
    )
}
