import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import config from "../config";
import { Avatar, Box, Button, Grid, Skeleton, Typography, Card, CardContent,  Dialog, DialogContent, DialogActions, Stack, Link } from "@mui/material";
import { LinkedIn, AlternateEmailOutlined, FacebookOutlined, LanguageOutlined, ArrowBack, Place, Email } from "@mui/icons-material";
import EntrepreneurCard from "./profileCard/EntrepreneurCard";
import IncubatorsAcceleratorsCard from "./profileCard/IncubatorsAcceleratorsCard";
import InvestorCard from "./profileCard/InvestorCard";
import TrainingProvidersCard from "./profileCard/TrainingProvidersCard";
import BlockBox from "./profileCard/BlockBox";
import ServerProxy from "./tools/serverProxy";
import { grey } from "@mui/material/colors";
import { useTheme} from '@mui/material/styles';
import { MyDividerSmall } from "./profileCard/MyDivider";


const sxTitle = {
    fontWeight: 'bold',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontSize: '.9rem',
    color: grey[700]
}

const sxValue = {
    textOverflow: 'ellipsis',
    width: '100%',
    fontSize: '.9rem',
    color: grey[700],
    maxHeight: '100px', 
    overflow: 'auto'
}





function EventsBox(props) {

    const { event } = props;          
    
    return (

        <Box sx={{ p: 2, width: { xs: '100%', md: 340 }, overflow: 'auto', backgroundColor: grey[200], my: 0, borderRadius: 5 }}>

            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <Typography sx={{...sxTitle}}>Title</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography title={event.title} sx={{...sxValue}}>{event.name}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{...sxTitle}}>Date</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography title={event.date} sx={{...sxValue}}>{new Date(event.date_at).toLocaleString()}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{...sxTitle}}>Place</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography title={event.date} sx={{...sxValue}}>{(event.online === 1 || !event.place)  ? 'Online' : event.place}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography sx={{...sxTitle}}>Subject</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography title={event.subject} sx={{...sxValue}}>{event.description}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                        <Button component={Link} href={event.url} target="_blank" sx={{textTransform: 'none'}} variant="outlined">Go to link</Button>
                    </Box>
                </Grid>

            </Grid>
        </Box>

    )
}

function EventsGroup(props) {

    const { events } = props;

    return (
        <>
            {(events && events.length > 0) ?
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ mb: 1, fontSize: '24px', fontWeight: 'bold' }} >
                        Events
                    </Typography>

                    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                        {events.map((event, index) => (
                            <EventsBox key={index} event={event} />
                        ))}
                    </Stack>
                </Box>
                :
                null
            }
        </>
    )
}



function MainCard( props ) {

    const theme = useTheme();
    const navigate = useNavigate();

    const { profile, events } = props;

    const userType = useMemo(() => {

        return {
            company: {
                label: 'Company',
                value: 'name',
            },
            nature: {
                label: 'Company insititutional nature',
                value: 'institutionalNature',
            },
            headquarter: {
                label: 'Headquarter',
                value: 'place',
            }
        };
    }, []);

    const [viewCompany, setViewCompany] = useState(false);


    function ViewCompanyDialog() {


        const moreUserType = useMemo(() => {

            return {
                company: {
                    label: 'Company',
                    value: 'name',
                },
                nature: {
                    label: 'Company insititutional nature',
                    value: 'institutionalNature',
                },
                headquarter: {
                    label: 'Headquarter',
                    value: 'place',
                },
                descrption: {
                    label: 'Descrption',
                    value: 'description',
                },
                email: {
                    label: 'Email',
                    value: 'email',
                    icon: <AlternateEmailOutlined color="primary" />
                },
                linkedIn: {
                    label: 'Url linkedin',
                    value: 'linkedIn',
                    icon: <LinkedIn color="primary" />
                },
                logoUrl: {
                    label: 'Url Logo',
                    value: 'logoUrl',
                },
                social: {
                    label: 'Social',
                    value: 'social',
                    icon: <FacebookOutlined color="primary" />
                },
                website: {
                    label: 'website',
                    value: 'website',
                    icon: <LanguageOutlined color="primary" />
                }
            }
        }, []);
    


        const handleClose = () => {
            setViewCompany(false);
        }

        return (
            <Dialog
                open={viewCompany}
                onClose={handleClose}
            >

                <DialogContent >
                    <Box sx={{ minWidth: {xs : 'auto', sm : 320} , p: 1, my: 2, borderRadius: '14px', overflow : 'auto' } }>

                        <Box>
                            {profile.user ? 
                            <Avatar
                                alt={`${profile.company['logoUrl']} `}
                                src={profile.company['logoUrl'] ? profile.company['logoUrl'] : null}
                                sx={{ width: config.avatarSize, height: config.avatarSize, mx: 'auto', border: '1px solid' }}
                            /> : <Skeleton variant="circular" width={config.avatarSize} sx={{ display: 'inline-block' }} height={config.avatarSize} />
                            }
                        </Box>



                            

                        <Stack spacing={0}>    
                        {Object.keys(moreUserType).map((key) => (

                            <Fragment key={key}>  
                                {moreUserType[key].label !== 'Email' && moreUserType[key].label !== 'Url linkedin' && moreUserType[key].label !== 'Url Logo' && moreUserType[key].label !== 'Social' && moreUserType[key].label !== 'website' && (

                                    <Box>
                                        <Typography
                                            title={moreUserType[key].value === 'place' ? profile.company.position.place : profile.company[moreUserType[key].value]}
                                            sx={{ p: 1, whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'center' }}>

                                            {moreUserType[key].value === 'place' ? profile.company.position.place : profile.company[moreUserType[key].value]}
                                        </Typography>
                                        {(moreUserType[key].label === 'Company' || moreUserType[key].label === 'Descrption') && (
                                            <MyDividerSmall /> 
                                        )}
                                    </Box>
                                )}

                                
                                {  (['Email', 'website', 'Url linkedin', 'Social'].includes(moreUserType[key].label) && 
                                    profile.company[moreUserType[key].value]) && (
                                    
                                    <Box>
                                        <Button component={Link}
                                            href={profile.company[moreUserType[key].value]}
                                            target="_blank" sx={{ color: 'black' }}
                                            startIcon={moreUserType[key].icon}>


                                            <Typography sx={{ fontSize: '.8rem', textTransform: 'lowercase', p: 0 }} component="span" className="Cabin Regular size14px">
                                                {profile.company[moreUserType[key].value]}
                                            </Typography>
                                        </Button>
                                    </Box>
                                )}
                            </Fragment>

                        ))}

                        </Stack>
                    </Box>
                   
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    return (

        <Card sx={{ width: '100%', paddingLeft: 0, paddingRight: 0, boxShadow: 3 }}>
            <CardContent sx={{overflow: 'auto'}}>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

                    <Typography sx={{ fontSize: { xs: '1.5rem', md: '1.6rem', lg: '2.4rem' } }}  color="text.primary" gutterBottom>
                        {`${profile.user.firstname} ${profile.user.lastname}`}
                    </Typography>

                    <Box sx={{  display : { xs: 'none', md: 'flex'}, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button onClick={e => navigate('/home')} variant="outlined" startIcon={<ArrowBack />}>
                            <Typography>Back to the Map</Typography>
                        </Button>
                    </Box>
                </Box>

                <MyDividerSmall />

                <Box sx={{ py: 2}}>

                    <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: { xs: 'wrap', sm : 'nowrap'}, gap: 2 }}>

                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: { xs: 'wrap', sm : 'nowrap'}, gap: 2, minWidth: {xs : '100%', sm: 320} }}>
                            
                            <Box sx={{width : { xs : '100%', sm: 'auto' }}}>
                                <Avatar
                                    alt={`${profile.user.firstname} ${profile.user.lastname}`}
                                    src={profile.user.avatarUrl ? config.server.backetUrl + profile.user.avatarUrl : null}
                                    sx={{ width: config.avatarSize, height: config.avatarSize,  mx: { xs : 'auto', sm : 'unset'}, mb:{xs : 2, sm : 0} }}
                                /> 
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'column', mb: 2}} >
                                
                                <Button size="small" component={Link} sx={{textTransform: 'none', mb: 2, display:'flex', flexWrap:'wrap'}} href={`mailto:${profile.user.email}`} target="_blank" startIcon={<Email sx={{color: theme.palette.primary.main }} />}>
                                    
                                    <Typography sx={{color: 'text.primary' }} variant="body2">{`${profile.user.email}`}</Typography>
                                </Button>

                                <Box sx={{width: 'auto'}} >
                                    <Box component='span' sx={{textTransform: 'uppercase', width: 'auto', p:1, borderRadius: 2,  bgcolor: 'rgba(141, 40, 173, 0.15)', color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                        {profile.profileMacroType.name}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>


                        <MyDividerSmall sx={{display: {xs : 'none', sm : 'inherit'} }}  orientation="vertical" flexItem />

                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >

                            <Typography sx={{mb: 1}} variant="body2">{profile.shortDescription || "Short description"}</Typography>

                            {profile.positions.map((position, index) => (
                                <Button size="small" key={index} sx={{textTransform: 'none'}} onClick={ () => props.flyTo(position) } startIcon={ <Place sx={{color: theme.palette.primary.main }} />} >
                                    <Typography sx={{color: 'text.primary' }} variant="body2">{position.place}</Typography>
                                </Button>
                            ))}
                        
                            {profile.user.linkedInUrl && (

                                <Button size="small" component={Link} sx={{textTransform: 'none'}} href={profile.user.linkedInUrl} target="_blank" startIcon={<LinkedIn sx={{ color: theme.palette.primary.main }} /> }>
                                    <Typography sx={{color: 'text.primary' }} variant="body2">{'Go to LinkedIn'}</Typography>
                                </Button>
                            )}
                        </Box>        
                    </Box>

                </Box>

                <MyDividerSmall />

                <Box sx={{ pt:2 , display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap :2, mb: 2 }}>

                    <Typography  component="span" sx={{fontWeight: 'bold'}}>User type</Typography>
                    <Box component='span' sx={{textTransform: 'uppercase', width: 'auto', p:1, borderRadius: 2,  bgcolor: 'rgba(141, 40, 173, 0.15)', color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {profile.userType}
                    </Box>
                </Box>

  
                {profile.userType === 'company' && (
                    <>
                        <ViewCompanyDialog />

                        <Box sx={{ p: 2, width: { xs: '100%', lg: '75%' }, overflow: 'auto', backgroundColor: grey[200],  mb: 2,  borderRadius: 5 }}>

                            <Grid container spacing={1}>
                            {Object.keys(userType).map((key) => (

                                <Fragment key={key}>    
                                    <Grid item xs={12} md={5}>
                                        <Typography sx={{ ...sxTitle}}>
                                            {userType[key].label}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} md={7}>
                                        <Typography title={userType[key].value === 'place' ? profile.company.position.place : profile.company[userType[key].value]}
                                            sx={{ ...sxValue, "&::first-letter": {
                                                textTransform: 'capitalize'
                                            } }}>

                                            {userType[key].value === 'place' ? profile.company.position.place : profile.company[userType[key].value]}
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            ))}
                            </Grid>

                            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <Button onClick={() => setViewCompany(true)} variant="outlined" sx={{  textTransform: 'none' }} >View company</Button>
                            </Box> */}
                        </Box>  
                    </>
                )}

                <MyDividerSmall /> 


                { (events && events.length > 0)  && (    
                    <>
                    <Box sx={{ py: 2 }}>
                        <EventsGroup events={events} />
                    </Box>

                    <MyDividerSmall />
                    </>            
                )}


                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', py: 2, gap: 2 }}>
                    <BlockBox title={'Business Core'} sizeTitle={18} data={profile.businessCores.map(core => core.name)} />
                    <BlockBox title={'What describes you'} sizeTitle={18} data={profile.profileTypes.map(core => core.name)} />
                    <BlockBox title={'What are you looking for'} sizeTitle={18} data={profile.lookingFor.map(core => core.name)} />
                </Box>

            </CardContent>
        </Card>
    )
}


export default function UserCard(props) {

    const navigate = useNavigate();

    const { id: idUser = '0', idProfile } = useParams();

    const [profile, setProfile] = useState();

    const [events, setEvents] = useState();

 
    useEffect(() => {

        const loadData = async () => {

            try {
                const profile = await ServerProxy.getProfile(idUser, idProfile);
                setProfile(profile);

                const events = await ServerProxy.getEvents(idUser);
                setEvents(events);

            }
            catch (error) {
                
                alert(error.message);

                console.error(error);
                navigate('/home');
            }
            
        }


        if (idUser !== '0') {
            setProfile(null);
            setEvents(null);

            loadData();
        }

    }, [idUser, idProfile])



    return (

        <Box sx={{p: { xs : 0.5, sm: 1, md: 2}, flex: 1,  marginTop: {xs: 0, md: '64px'}  , overflow: 'auto'}} >

            {profile && (

                <Box>
                    <MainCard {...props} profile={profile} events={events} />

                    {profile.profileMacroType.id === '1' && <EntrepreneurCard idProfile={profile.id} title={'Entrepreneur'} />}
                    {profile.profileMacroType.id === '2' && <IncubatorsAcceleratorsCard idProfile={profile.id} title={'Incubators / Accelerators'} />}
                    {profile.profileMacroType.id === '3' && <InvestorCard idProfile={profile.id} />}
                    {profile.profileMacroType.id === '7' && <EntrepreneurCard idProfile={profile.id} title={'Start Up'} />}
                    {profile.profileMacroType.id === '4' && <TrainingProvidersCard idProfile={profile.id} profileMacroType={profile.profileMacroType.id} />}

                </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 3 }}>
                <Button onClick={e => navigate('/home')} variant="outlined" startIcon={<ArrowBack />}>
                    <Typography>Back to the Map</Typography>
                </Button>
            </Box>
        </Box>
    )
}
